<template>
    <div class="stage-inner-container-padded">
        <b-row class="h-100" no-gutters>
            <b-col cols="12" class="pt-2">
                <h2>Account Management</h2>
            </b-col>
            <b-col cols="12" lg="4" xl="2" class="pt-2 pb-3 px-2">
                <span 
                    class="font-weight-bold fs-1-25"
                    v-if="$store.getters.checkUIPC({path: ['accountManagement', 'actions','manageAccountUsers']})"
                >Organization Administration</span>
                <b-list-group class="mb-3">
                    <b-list-group-item 
                        v-if="$store.getters.checkUIPC({path: ['accountManagement', 'actions','manageAccountUsers']})"
                        button 
                        :active="(curView == 'users')"
                        @click="curView = 'users';"
                    >
                        Users
                    </b-list-group-item>
                </b-list-group>
                <span 
                    class="font-weight-bold fs-1-25"
                    v-if="$store.getters.checkUIPC({path: ['accountManagement', 'actions','setPortalAutomationRules']})"
                >Compliance Configuration</span>
                <b-list-group>
                    <b-list-group-item 
                        v-if="$store.getters.checkUIPC({path: ['accountManagement', 'actions','setPortalAutomationRules']})"
                        @click="curView = 'hazmap';"
                        button 
                        :active="(curView == 'hazmap')"
                    >
                        Hazard v. Type Mapping
                    </b-list-group-item>
                    <b-list-group-item 
                        v-if="$store.getters.checkUIPC({path: ['accountManagement', 'actions','setPortalAutomationRules']})"
                        @click="curView = 'sinterval';"
                        button 
                        :active="(curView == 'sinterval')"
                    >
                        Site Type Intervals
                    </b-list-group-item>
                    <b-list-group-item 
                        v-if="$store.getters.checkUIPC({path: ['accountManagement', 'actions','setPortalAutomationRules']}) && $store.getters.getAccountConfigPortalAvailable"
                        @click="curView = 'portalauto';"
                        button 
                        :active="(curView == 'portalauto')"
                    >
                        Portal Automation
                    </b-list-group-item>
                </b-list-group>
            </b-col>
            <b-col cols="12" lg="8" xl="10" v-if="(curView == 'hazmap') && $store.getters.checkUIPC({path: ['accountManagement', 'actions','setPortalAutomationRules']}) && $store.getters.getAccountConfigPortalAvailable">
                <b-card
                    header-bg-variant="vai-secondary-medium"
                >
                    <template #header>
                        <h4 class="mb-0">Protection Sufficiency For Each Hazard Type</h4>
                    </template>
                    <b-card-body class="bg-vai-gray-100 px-0 py-0">
                        <haz-map></haz-map>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="12" lg="8" xl="10" v-if="(curView == 'sinterval') && $store.getters.checkUIPC({path: ['accountManagement', 'actions','setPortalAutomationRules']}) && $store.getters.getAccountConfigPortalAvailable">
                <b-card
                    header-bg-variant="vai-secondary-medium"
                >
                    <template #header>
                        <h4 class="mb-0">Required Action Frequency For Each Site Type</h4>
                    </template>
                    <b-card-body class="bg-vai-gray-100 px-0 py-0">
                        <site-interval></site-interval>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="12" lg="8" xl="10" v-if="(curView == 'portalauto') && $store.getters.checkUIPC({path: ['accountManagement', 'actions','setPortalAutomationRules']}) && $store.getters.getAccountConfigPortalAvailable">
                <b-card
                    header-bg-variant="vai-secondary-medium"
                >
                    <template #header>
                        <h4 class="mb-0">Portal Automation Ruleset Configuration</h4>
                    </template>
                    <b-card-body class="bg-vai-gray-100">
                        <portal-ruleset></portal-ruleset>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="12" lg="8" xl="10" v-if="(curView == 'users') && $store.getters.checkUIPC({path: ['accountManagement', 'actions','manageAccountUsers']})">
                <b-card
                    header-bg-variant="vai-secondary-medium"
                >
                    <template #header>
                        <div class="d-flex flex-row justify-content-between">
                            <h4 class="mb-0">Account Users</h4>
                            <div>
                                <b-form-checkbox
                                    v-model="showUnbilled"
                                    switch
                                    size="lg"
                                >Show Unbilled Users <b-icon-question-circle id="automated-users-help"></b-icon-question-circle></b-form-checkbox>
                            </div>
                        </div>
                        <b-popover target="automated-users-help" triggers="hover" placement="leftbottom">
                            <template #title>Unbilled Users</template>
                            Unbilled users do not consume any user seats that may be associated with your account.
                            Unbilled users are typically administrative in nature, such as automation engine users, and support staff users.
                            <hr>
                            <b>Altering Unbilled Users Is Not Supported</b> 
                        </b-popover>
                    </template>
                    <b-card-body class="bg-vai-gray-100">
                        <user-management
                            :showUnBilledUsers="showUnbilled"
                        ></user-management>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');

import PortalRulesetManagement from '@/components/subcomponents/PortalRuleset.vue'
import UserManagement from '@/views/applets/UserManagement.vue'
import HazardMapping from '@/views/applets/HazardVsTypeSetup.vue'
import SiteInterval from '@/views/applets/SiteTypeIntervalSetup.vue'

export default {
    name: 'AccountManagement',
    components:{
        'portal-ruleset': PortalRulesetManagement,
        'user-management': UserManagement,
        'haz-map': HazardMapping,
        'site-interval': SiteInterval
    },
    props:{

    },
    data(){
        return{
            showUnbilled: false,
            curView: 'users'
        }
    },
    methods:{

    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){

    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>