<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group
                    id="pars-unable-to-match-label"
                    description="What happens when a work order arrives, but Vai can't find a matching device in your database."
                    label="When No Device Match Found"
                    label-for="pars-unable-to-match"
                >
                    <b-form-select id="pars-unable-to-match" v-model="unableToMatch">
                        <b-form-select-option value="review">Add To Manual Review List (Recommended)</b-form-select-option>
                        <b-form-select-option value="reject">Reject</b-form-select-option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-missing-gps-data-label"
                    description="What happens when a work order arrives and it has no GPS information."
                    label="When No GPS Data Is Provided"
                    label-for="pars-missing-gps-data"
                >
                    <b-form-select id="pars-missing-gps-data" v-model="missingGPSData" :options="basicOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-office-entry-label"
                    description="What happens when we know that the work order was entered via desktop computer. I.E. Not in the field."
                    label="When the work order was entered on a desktop computer"
                    label-for="pars-office-entry"
                >
                    <b-form-select id="pars-office-entry" v-model="officeEntry" :options="basicOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-hazard-type-unknown-label"
                    description="The technician has specified a hazard type that doesn't match any in your system."
                    label="When the provided hazard type isn't recognized"
                    label-for="pars-hazard-type-unknown"
                >
                    <b-form-select id="pars-hazard-type-unknown" v-model="invalidHazardType" :options="basicOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-device-model-unknown-label"
                    description="The technician has specified a device model that isn't recognized by your system."
                    label="When the provided device model isn't recognized"
                    label-for="pars-device-model-unknown"
                >
                    <b-form-select id="pars-device-model-unknown" v-model="invalidDeviceModel" :options="basicOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-device-type-mismatch-label"
                    description="The system found a matching device for the record, but the technician has specified a device type that is different to the device record. This is rare, but can occur when technicians fail to perform a replacement, or do not verify serial and meter numbers."
                    label="When the device type is different to the one on record"
                    label-for="pars-device-type-mismatch"
                >
                    <b-form-select id="pars-device-type-mismatch" v-model="deviceTypeMismatch" :options="basicOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-require-bfpa-photo-label"
                    description="The work order contains a test, but does not have a BFPA photo. What should happen?"
                    label="Photo of BFPA is Required (Applies to Test)"
                    label-for="pars-require-bfpa-photo"
                >
                    <b-form-select id="pars-require-bfpa-photo" v-model="bfpaPhoto" :options="photoOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-require-overall-before-label"
                    description="The work order contains a replacement, but does not have a overall photo before replacement begins. What should happen?"
                    label="Overall Photo Before Replacement Begins (Applies to Replacement)"
                    label-for="pars-require-overall-before"
                >
                    <b-form-select id="pars-require-overall-before" v-model="overallBefore" :options="photoOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-require-close-up-before-label"
                    description="The work order contains a replacement, but does not have a close up photo before replacement begins. What should happen?"
                    label="Close Up Photo Before Replacement Begins (Applies to Replacement)"
                    label-for="pars-require-close-up-before"
                >
                    <b-form-select id="pars-require-close-up-before" v-model="closeUpBefore" :options="photoOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-require-before-fill-label"
                    description="The work order contains a replacement, but does not have a close up photo of the device before fill dirt. What should happen?"
                    label="Close Up Photo Before Fill Dirt (Applies to Replacement)"
                    label-for="pars-require-before-fill"
                >
                    <b-form-select id="pars-require-before-fill" v-model="beforeFillDirt" :options="photoOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-require-overall-after-label"
                    description="The work order contains a replacement, but does not have an overall photo after the replacement. What should happen?"
                    label="Overall Photo After Replacement (Applies to Replacement)"
                    label-for="pars-require-overall-after"
                >
                    <b-form-select id="pars-require-overall-after" v-model="overallAfter" :options="photoOptions"></b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-auto-create-tech-label"
                    description="If your orgnaization has never received work from a particular technician, and that technician sends a work order to you, what should happen? (External Technician Certifications Are Verfied By Valve Ai Before Submission)"
                    label="The First Time A Technician Submits Work"
                    label-for="pars-auto-create-tech-label"
                >
                    <b-form-select id="pars-auto-create-tech" v-model="autoCreateTech">
                        <b-form-select-option :value="false">Send For Manual Review</b-form-select-option>
                        <b-form-select-option :value="true">Automatically Add Technician To My Technician List</b-form-select-option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group
                    id="pars-require-manual-label"
                    description="You may opt to manually review every portal submission even if the portal automation system would accept the result automatically otherwise. NOTE: Automated rejections still occur."
                    label="Require Manual Review For All Submissions"
                    label-for="pars-require-manual"
                >
                    <b-form-select id="pars-require-manual" v-model="manualReview">
                        <b-form-select-option :value="true">Require Manual Review For All Non-Rejected Records</b-form-select-option>
                        <b-form-select-option :value="false">Manually Review Only Records Which Require Review</b-form-select-option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-button class="mr-5" :disabled="!dirty" variant="warning" @click="fetchCurrent">Discard Changes</b-button>
                <b-button variant="success" :disabled="!dirty" @click="saveRuleset">Save Ruleset</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');

export default {
    name: 'PortalRulesetMgmt',
    components:{

    },
    props:{

    },
    data(){
        return{
            dirty: false,
            watchersSet: false,
            photoOptions:[
                {value: 'accept', text:'Accept Without Photo. This photo is optional.'},
                {value: 'review', text:'Add To Manual Review List.'},
                {value: 'reject', text:'Reject The Work Order. This photo is REQUIRED for a test.'}
            ],
            basicOptions: [
                {value: 'accept', text:'Accept'},
                {value: 'review', text:'Manual Review'},
                {value: 'reject', text:'Reject'}
            ],
            // ID
            rulesetID: null,
            // Fields
            unableToMatch: 'review',
            missingGPSData: 'reject',
            officeEntry: 'review',
            invalidHazardType: 'review',
            invalidDeviceModel: 'review',
            deviceTypeMismatch: 'review',
            bfpaPhoto: 'reject',
            overallBefore: 'review',
            closeUpBefore: 'review',
            beforeFillDirt: 'accept',
            overallAfter: 'review',
            autoCreateTech: false,
            manualReview: false,
        }
    },
    methods:{
        setupWatchers(){
            this.$watch('unableToMatch', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('missingGPSData', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('officeEntry', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('invalidHazardType', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('invalidDeviceModel', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('deviceTypeMismatch', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('bfpaPhoto', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('overallBefore', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('closeUpBefore', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('beforeFillDirt', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('overallAfter', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('autoCreateTech', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.$watch('manualReview', (newValue, oldvalue) => {
                if(newValue != oldvalue){
                    this.dirty = true;
                }
            });
            this.watchersSet = true;
        },
        fetchCurrent(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/portal/ruleset`)
            .then((response)=>{
                if(response.data.status == 200){
                    // We have a valid ruleset
                    var loaded = response.data.result;
                    this.rulesetID = loaded.id;
                    this.unableToMatch = loaded.unable_to_match;
                    this.missingGPSData = loaded.missing_gps_data;
                    this.officeEntry = loaded.performed_at_office;
                    this.invalidHazardType = loaded.invalid_hazard_type;
                    this.invalidDeviceModel = loaded.invalid_device_model;
                    this.deviceTypeMismatch = loaded.mismatched_device_type;
                    this.bfpaPhoto = loaded.missing_test_bfpa_photo;
                    this.overallBefore = loaded.missing_replacement_overall_photo_before;
                    this.closeUpBefore = loaded.missing_replacement_close_up_photo_before;
                    this.beforeFillDirt = loaded.missing_replacement_photo_of_installed_bfpa_before_fill_dirt;
                    this.overallAfter = loaded.missing_replacement_overall_photo_after;
                    this.autoCreateTech = loaded.create_new_technician_automatically;
                    this.manualReview = loaded.force_manual_review;
                    if(!this.watchersSet){
                        this.setupWatchers();
                    }else{
                        this.dirty = false;
                    }
                }else{
                    // No Ruleset Exists
                    if(!this.watchersSet){
                        this.setupWatchers();
                    }else{
                        this.dirty = false;
                    }
                }
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Fetch Portal Ruleset By Endpoint Control', 'Contact your administrator to receive permission to perform portal ruleset management', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Fetching Portal Ruleset', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        saveRuleset(){
            var rules = {
                id: this.rulesetID,
                unable_to_match: this.unableToMatch,
                missing_gps_data: this.missingGPSData,
                performed_at_office: this.officeEntry,
                invalid_hazard_type: this.invalidHazardType,
                invalid_device_model: this.invalidDeviceModel,
                mismatched_device_type: this.deviceTypeMismatch,
                missing_test_bfpa_photo: this.bfpaPhoto,
                missing_replacement_overall_photo_before: this.overallBefore,
                missing_replacement_close_up_photo_before: this.closeUpBefore,
                missing_replacement_photo_of_installed_bfpa_before_fill_dirt: this.beforeFillDirt,
                missing_replacement_overall_photo_after: this.overallAfter,
                create_new_technician_automatically: this.autoCreateTech,
                force_manual_review: this.manualReview
            };
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/portal/ruleset`, rules)
            .then((response)=>{
                butils.createToast(this, "Saved", "Portal Ruleset Saved Successfully", "success")
                this.dirty = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Fetch Portal Ruleset By Endpoint Control', 'Contact your administrator to receive permission to perform portal ruleset management', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Saving Portal Ruleset', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        this.fetchCurrent();
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>