<template>
    <div class="stage-inner-container-padded">
        <div class="d-flex flex-row-reverse my-2">
            <b-button 
                variant="warning"
                v-b-modal.add-user
            >
                <b-icon-person-plus-fill></b-icon-person-plus-fill> Add User
            </b-button>
        </div>
        
        <b-table
            small
            striped 
            stacked="lg"
            :items="showRows"
            :fields="fields"
        >
            <template #cell(email)="data">
                {{data.item.username}}
            </template>
            <template #cell(first)="data">
                {{data.item.first_name}}
            </template>
            <template #cell(last)="data">
                {{data.item.last_name}}
            </template>
            <template #cell(frozen)="data">
                <div v-if="data.item.enabled">No</div>
                <div v-if="!data.item.enabled">Yes, Frozen On:</div>
                <div v-if="!data.item.enabled">{{data.item.disabledParsed}}</div>
            </template>
            <template #cell(last_login)="data">
                <div v-if="data.item.last_login_detail == null">Has Not Logged In Yet</div>
                <div v-else>
                    <span class="font-weight-bold">Last Login: </span><span>{{data.item.lastLoginAtParsed}}</span><br>
                    <span class="font-weight-bold">IP: </span><span>{{data.item.last_login_detail.ip}}</span><br>
                    <span class="font-weight-bold">Detail: </span>
                        <span>{{data.item.last_login_detail.os}} </span> 
                        <span>{{data.item.last_login_detail.browser}} </span>
                        <span>{{data.item.last_login_detail.version}}</span>
                    <br>
                </div>
            </template>
            <template #cell(actions)="data">
                <b-dropdown variant="info" :id="`user-action-${data.item.id}`" text="Actions">
                    <b-dropdown-item :disabled="!data.item.billed" @click="openEdituser(data.item.id)">Edit User Info</b-dropdown-item>
                    <b-dropdown-item v-if="data.item.enabled" :disabled="!data.item.billed" @click="confirmFreezeUser(data.item.id)">Freeze User</b-dropdown-item>
                    <b-dropdown-item v-if="!data.item.enabled" :disabled="!data.item.billed" @click="confirmUnFreezeUser(data.item.id)">Unfreeze User</b-dropdown-item>
                    <!-- <b-dropdown-item @click="openChangePass(data.item.id)">Change Password</b-dropdown-item> -->
                    <b-dropdown-item v-if=" $store.getters.checkUIPC({path: ['technicians', 'actions','update']})" :disabled="!data.item.billed" @click="openAssignTech(data.item.id)">Assign Technician</b-dropdown-item>
                </b-dropdown>
            </template>
        </b-table>
        <b-modal id="edit-user" title="Edit User">
            <b-row v-if="waiting">
                <b-col cols="12">
                    <wait-dots title="Updating User"></wait-dots>
                </b-col>
            </b-row>
            <b-row v-if="editing != null && !waiting">
                <b-col cols="12">
                    <text-input
                        :dataName="'first_name'"
                        :inputLabel="'First Name'"
                        :required="true"
                        :value="editing.first_name"
                        :parentFormID="entryID"
                    ></text-input>
                </b-col>
                <b-col cols="12">
                    <text-input
                        :dataName="'last_name'"
                        :inputLabel="'Last Name'"
                        :required="true"
                        :value="editing.last_name"
                        :parentFormID="entryID"
                    ></text-input>
                </b-col>
                <b-col cols="12">
                    <text-input
                        :dataName="'display_name'"
                        :inputLabel="'Display Name'"
                        :required="true"
                        :value="editing.display_name"
                        :parentFormID="entryID"
                    ></text-input>
                </b-col>
            </b-row>
            <template #modal-footer="{ cancel }">
                <b-button v-if="!waiting" size="sm" class="mr-3" variant="warning" @click="cancel()">
                    Discard Changes
                </b-button>
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button v-if="!waiting" size="sm" variant="success" @click="performUserEdit">
                    OK
                </b-button>
            </template>
        </b-modal>
        <b-modal
            id="assign-technician"
            title="Assign Technician"
        >
            <b-row v-if="waiting">
                <b-col cols="12">
                    <wait-dots title="Updating Technician"></wait-dots>
                </b-col>
            </b-row>
            <b-row v-if="assign != null && !waiting">
                <b-col cols="12">
                    <h3>Set Technician For {{assign.display_name}}</h3>
                </b-col>
            </b-row>
            <b-row v-if="!waiting">
                <b-col cols="12">
                    <b-form-group
                        id="fieldset-1"
                        description="Technician Record That Is Associated With This User"
                        label="Select Technician"
                        label-for="tech-assign"
                    >
                        <b-input-group>
                            <b-form-select 
                                id="tech-assign" 
                                v-model="selectedTech" 
                                :options="technicianChoices" 
                                @change="warnIfTechAlreadyAssigned($event, true)"
                                trim
                            ></b-form-select>
                            <b-input-group-append>
                                <b-button 
                                    v-if="selectedTech != null"
                                    variant="danger" 
                                    v-b-tooltip.hover title="Clear Technician Selection"
                                    @click="()=>{selectedTech = null; warnIfTechAlreadyAssigned(null, true); }"
                                >
                                    <b-icon-x-circle-fill></b-icon-x-circle-fill>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template #modal-footer="{ cancel }">
                <b-button v-if="!waiting" size="sm" class="mr-3" variant="warning" @click="cancel()">
                    Discard Changes
                </b-button>
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button v-if="!waiting" size="sm" variant="success" @click="performSetTech">
                    OK
                </b-button>
            </template>
        </b-modal>
        <b-modal
            id="add-user"
            title="Add New Account User"
            size="lg"
            v-model="addUserModalShowing"
        >
            <b-row v-if="waiting">
                <b-col cols="12">
                    <wait-dots title="Creating User"></wait-dots>
                </b-col>
            </b-row>
            <div v-if="!waiting">
                <!-- User Info Block -->
                <div class="d-flex flex-column">
                    <div>
                        <span class="fs-1-5 bg-vai-gray-100 shadow rounded-top px-3">
                            User Info
                        </span>
                    </div>
                    <div class="bg-vai-gray-100 rounded-right shadow rounded-bottom w-100 px-3 py-2">
                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group
                                    id="new-user-first-name-label"
                                    description=""
                                    label="First Name"
                                    label-for="new-user-first-name"
                                    :state="newUserValStates.first"
                                    :invalid-feedback="newUserValStates.firstDesc"
                                >
                                    <b-form-input 
                                        id="new-user-first-name" 
                                        v-model="newUserModal.first"
                                        :state="newUserValStates.first"
                                        trim
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group
                                    id="new-user-last-name-label"
                                    description=""
                                    label="Last Name"
                                    label-for="new-user-last-name"
                                    :state="newUserValStates.last"
                                    :invalid-feedback="newUserValStates.lastDesc"
                                >
                                    <b-form-input 
                                        id="new-user-last-name" 
                                        v-model="newUserModal.last"
                                        :state="newUserValStates.last"
                                        trim
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group
                                    id="new-user-display-name-label"
                                    description="This is the name that will be displayed."
                                    label="Display Name"
                                    label-for="new-user-display-name"
                                    :state="newUserValStates.display"
                                    :invalid-feedback="newUserValStates.displayDesc"
                                >
                                    <b-form-input 
                                        id="new-user-display-name"
                                        v-model="newUserModal.display"
                                        :state="newUserValStates.display"
                                        trim
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div class="d-flex flex-column mt-4">
                    <div>
                        <span class="fs-1-5 bg-vai-gray-100 shadow rounded-top px-3">
                            Login Details
                        </span>
                    </div>
                    <div class="bg-vai-gray-100 rounded-right shadow rounded-bottom w-100 px-3 py-2">
                        <b-row>
                            <b-col cols="12">
                                <b-form-group
                                    id="new-user-email-label"
                                    description="This email will be used as the user's username."
                                    label="Email"
                                    label-for="new-user-email"
                                    :state="newUserValStates.email"
                                    :invalid-feedback="newUserValStates.emailDesc"
                                >
                                    <b-form-input 
                                        id="new-user-email" 
                                        v-model="newUserModal.email"
                                        :state="newUserValStates.email"
                                        trim
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group label="Password" label-class="fs-1-25" v-slot="{ ariaDescribedby }">
                                    <b-form-radio class="ml-3 my-2" v-model="newUserModal.autoSetPass" :aria-describedby="ariaDescribedby" name="password-set-type-radios" :value="true">
                                        Have User Set Password <span class="text-success ">Strongly Recommended</span><br>
                                        <small>
                                            The user will receive an email at the address provided which will notify them that their Valve Ai account has been created.<br>
                                            This email will provide a link that allows them to set their password and get logged in.
                                        </small>
                                    </b-form-radio>
                                    <b-form-radio class="ml-3" v-model="newUserModal.autoSetPass" :aria-describedby="ariaDescribedby" name="password-set-type-radios" :value="false">
                                        Set Password Now<br>
                                        <small>
                                            <span :class="(!newUserModal.autoSetPass) ? `font-weight-bold` : ``">
                                                Make sure to notify the user that you have created their account and ensure that you provide the login details you create.<br>
                                            </span>
                                            <span :class="(!newUserModal.autoSetPass) ? `text-danger` : ``">
                                                Valve Ai will not notify the user that an account has been created, and cannot provide this password to the user.
                                            </span>
                                        </small>
                                    </b-form-radio>
                                </b-form-group>
                                <div v-if="!newUserModal.autoSetPass">
                                    <b-form-group
                                        id="new-user-password-label"
                                        label="Password"
                                        label-for="new-user-password"
                                        :state="newUserValStates.password"
                                        :invalid-feedback="newUserValStates.passwordDesc"
                                    >
                                        <b-form-input 
                                            id="new-user-password"
                                            type="password" 
                                            v-model="newUserModal.password"
                                            :state="newUserValStates.password"
                                            trim
                                            aria-describedby="new-user-password-help-block"
                                        ></b-form-input>
                                        <b-form-text id="new-user-password-help-block">
                                            At least 5 characters, no length limit.<br>
                                            Accepts any valid unicode characters, including emoji, feel free to get as crazy as you want.<br>
                                            Cannot begin or end with spaces. Spaces at the begining or end will be removed.<br>
                                            <br>
                                            Valve Ai Recommends, But Does Not Require The Following Password Characteristics:<br>
                                            <ul>
                                                <li>Length of at least 8 characters.</li>
                                                <li>Upper and lowercase characters</li>
                                                <li>Special characters</li>
                                            </ul>
                                        </b-form-text>
                                    </b-form-group>
                                    <b-form-group
                                        id="new-user-password-verify-label"
                                        label="Confirm Password"
                                        label-for="new-user-password-verify"
                                        :state="newUserValStates.passwordVerify"
                                        :invalid-feedback="newUserValStates.passwordVerifyDesc"
                                    >
                                        <b-form-input 
                                            id="new-user-password-verify"
                                            type="password" 
                                            v-model="newUserModal.passwordVerify"
                                            :state="newUserValStates.passwordVerify"
                                            trim
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div class="d-flex flex-column mt-4">
                    <div>
                        <span class="fs-1-5 bg-vai-gray-100 rounded-top shadow px-3">
                            Permissions
                        </span>
                    </div>
                    <div class="bg-vai-gray-100 rounded-right rounded-bottom shadow w-100 px-3 py-2">
                        <b-row>
                            <b-col cols="12">
                                <b-form-group label="Permissions Setup Type" label-class="fs-1-25" v-slot="{ ariaDescribedby }">
                                    <b-form-radio class="ml-3 my-2" v-model="newUserModal.permsEasyMode" :aria-describedby="ariaDescribedby" name="perms-set-type-radios" :value="true">
                                        Simple <span class="text-success">Strongly Recommended</span>
                                    </b-form-radio>
                                    <b-form-radio class="ml-3" v-model="newUserModal.permsEasyMode" :aria-describedby="ariaDescribedby" name="perms-set-type-radios" :value="false">
                                        Advanced
                                    </b-form-radio>
                                </b-form-group>
                                <div v-if="newUserModal.permsEasyMode" class="bg-white p-2 rounded-lg shadow-sm">
                                    <div>
                                        <span class="fs-1-25">
                                            Simple Permissions Setup
                                        </span>
                                    </div>
                                    <b-form-group
                                        id="new-user-give-admin-label"
                                        description="Grants the General Administration Permission Set. Allows the creation and modification of users, along with access to all available modules."
                                        label="Administrator"
                                        label-for="new-user-give-admin"
                                    >
                                        <b-form-checkbox 
                                            v-model="newUserModal.permsGrantAdmin"
                                            :value="true"
                                            :unchecked-value="false"
                                            switch 
                                            size="lg"
                                        >
                                            Grant Administrator Privileges
                                        </b-form-checkbox>
                                    </b-form-group>
                                </div>
                                <div v-show="!newUserModal.permsEasyMode" class="bg-white p-2 rounded-lg shadow-sm">
                                    <div>
                                        <span class="fs-1-25">
                                            Advanced Permissions Setup
                                        </span>
                                    </div>
                                    <b-tabs 
                                        id="perm-setup-tab-cont" 
                                        content-class="mt-3"
                                    >
                                        <b-tab title="Endpoint Control Permissions" active>
                                            <endpoint-perm-builder
                                                ref="epc-perms-builder"
                                                :showResults="false"
                                                :grantAdmin="newUserModal.permsGrantAdmin"
                                                :onCompleted="epcUpdate"
                                            ></endpoint-perm-builder>
                                        </b-tab>
                                        <b-tab title="User Interface Permissions">
                                            <ui-perm-builder
                                                ref="uic-perms-builder"
                                                :showResults="false"
                                                :grantAdmin="newUserModal.permsGrantAdmin"
                                                :onCompleted="uicUpdate"
                                            ></ui-perm-builder>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div class="d-flex flex-column mt-4">
                    <div>
                        <span class="fs-1-5 bg-vai-gray-100 rounded-top shadow px-3">
                            Assign Technician
                        </span>
                    </div>
                    <div class="bg-vai-gray-100 rounded-right rounded-bottom shadow w-100 px-3 py-2">
                        <b-row>
                            <b-col cols="12">
                                <b-form-group
                                    id="new-user-tech-select-label"
                                    description="Technician Record That Is Associated With This User"
                                    label="Associate Technician With User"
                                    label-for="new-user-tech-select"
                                >
                                    <b-input-group>
                                        <b-form-select 
                                            id="new-user-tech-select" 
                                            v-model="newUserModal.assignedTech" 
                                            :options="technicianChoices" 
                                            @change="warnIfTechAlreadyAssigned($event, false)"
                                            trim
                                        ></b-form-select>
                                        <b-input-group-append>
                                            <b-button 
                                                v-if="newUserModal.assignedTech != null"
                                                variant="danger" 
                                                v-b-tooltip.hover title="Clear Technician Selection"
                                                @click="()=>{newUserModal.assignedTech = null; warnIfTechAlreadyAssigned(null, false); }"
                                            >
                                                <b-icon-x-circle-fill></b-icon-x-circle-fill>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>

            <template #modal-footer="{ cancel }">
                <b-button v-if="!waiting" size="sm" class="mr-3" variant="warning" @click="cancel()">
                    Discard Changes
                </b-button>
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button v-if="!waiting" size="sm" variant="success" :disabled="!createUserCompleted" @click="createUser">
                    Create User
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = butils.underscore;
const uuidv4 = butils.uuidv4;

import RegexText from '@/components/FormTextRegexInput.vue'
import TextArea from '@/components/FormTextAreaInput.vue'
import PassedSelect from '@/components/FormSelectPassedValuesInput.vue'
import APISelect from '@/components/FormSelectFetchAPIInput.vue'
import Decimal2x2Input from '@/components/FormDecimalTwoByTwoInput.vue'
import DecimalInput from '@/components/FormDecimalInput.vue'
import IntegerInput from '@/components/FormIntegerInput.vue'
import GPSInput from '@/components/FormGPSInput.vue'
import PhoneInput from '@/components/FormPhoneInput.vue'
import EmailInput from '@/components/FormEmailInput.vue'
import FormArrayInput from '@/components/FormArrayEngine.vue'
import RadioInput from '@/components/FormRadioInput.vue'
import BoolInput from '@/components/FormBooleanInput.vue'
import NestedForm from '@/components/NestedChildrenForms.vue'
import EventList from '@/components/FormEventListHistory.vue'
import DateInput from '@/components/FormDateInput.vue'
import TimeInput from '@/components/FormTimeInput.vue'
import DatePicker from '@/components/FormDatePickerInput.vue'
import WaitingDots from '@/components/subcomponents/WaitingDots.vue'
import EndpointPermBuilder from '@/views/applets/EndpointPermissionsBuilder.vue'
import UIPermBuilder from '@/views/applets/UIPermissionsBuilder.vue'

export default {
    name: 'UserManagement',
    components:{
        'text-input': RegexText,
        'decimal-2x2-input': Decimal2x2Input,
        'text-area': TextArea,
        'passed-select': PassedSelect,
        'api-select': APISelect,
        'decimal-input': DecimalInput,
        'integer-input': IntegerInput,
        'gps-input': GPSInput,
        'phone-input': PhoneInput,
        'email-input': EmailInput,
        'array-input': FormArrayInput,
        'radio-select': RadioInput,
        'bool-select': BoolInput,
        'nested-child-forms': NestedForm,
        'event-list': EventList,
        'date-input': DateInput,
        'time-input': TimeInput,
        'date-picker': DatePicker,
        'wait-dots': WaitingDots,
        'endpoint-perm-builder': EndpointPermBuilder,
        'ui-perm-builder': UIPermBuilder
    },
    props:{
        showUnBilledUsers: {
            type: Boolean,
            default: ()=>{return false;}
        },
    },
    data(){
        return{
            entryID: null,
            rawUserRows: [],
            fields: ["email","first","last","display_name","frozen","last_login","actions"],
            editing: null,
            assign: null,
            formDef:{
                meta: {
                    name: 'User Management',
                    title: 'User Management',
                    formParentKey: null
                },
                fields: {}
            },
            waiting: false,
            rawTechs: [],
            technicianChoices: [],
            selectedTech: null,
            newUserModal:{
                first: null,
                last: null,
                display: null,
                email: null,
                autoSetPass: true,
                password: null,
                passwordVerify: null,
                epcJSON: null,
                uicJSON: null,
                assignedTech: null,
                showValidation: false,
                permsEasyMode: true,
                permsGrantAdmin: false,
            },
            newUserValStates:{
                first: null,
                firstDesc: null,
                last: null,
                lastDesc: null,
                display: null,
                displayDesc: null,
                email: null,
                emailDesc: null,
                password: null,
                passwordDesc: null,
                passwordVerify: null,
                passwordVerifyDesc: null,
                epcJSON: null,
                epcDesc: null,
                uicJSON: null,
                uicDesc: null,
                assignedTech: null,
                techDesc: null
            },
            addUserModalShowing: false
        }
    },
    methods:{
        resetNewUserModal(){
            this.newUserModal = {
                first: null,
                last: null,
                display: null,
                email: null,
                autoSetPass: true,
                password: null,
                passwordVerify: null,
                epcJSON: null,
                uicJSON: null,
                assignedTech: null,
                showValidation: false,
                permsEasyMode: true,
                permsGrantAdmin: false,
            };
            this.newUserValStates = {
                first: null,
                firstDesc: null,
                last: null,
                lastDesc: null,
                display: null,
                displayDesc: null,
                email: null,
                emailDesc: null,
                password: null,
                passwordDesc: null,
                passwordVerify: null,
                passwordVerifyDesc: null,
                epcJSON: null,
                epcDesc: null,
                uicJSON: null,
                uicDesc: null,
                assignedTech: null,
                techDesc: null
            };
        },
        epcUpdate(epcVal){
            console.log("Got EPC Update")
            this.newUserModal.epcJSON = epcVal;
        },
        uicUpdate(uicVal){
            console.log("Got UIC Update")
            this.newUserModal.uicJSON = uicVal;
        },
        createUser(){
            this.waiting = true;
            butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/account/users`, this.newUserModal)
            .then((response)=>{
                console.log(response);
                this.waiting = false;
                this.addUserModalShowing = false;
            })
            .catch((err)=>{
                this.waiting = false;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permissions To Create New User Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action.', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Updating Technician User', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        warnIfTechAlreadyAssigned(techIDSelected, isStdAssign){
            // Get Raw Tech Record For Selected Technician
            if(techIDSelected != null){
                var techRecord = _.find(this.rawTechs, (tech)=>{ return tech.id == techIDSelected; })
                if(techRecord == undefined){
                    this.$bvModal.msgBoxConfirm(`Somehow you've selected an invalid technician. This selection will be cleared.`, {
                        title: "Technician Invalid",
                        buttonSize: "sm",
                        cancelDisabled: true,
                        cancelVariant: "vai-warning-darker",
                        okVariant: "vai-warning-darker",
                        okTitle: "Clear Invalid Technician Selection"
                    })
                    .then((value)=>{
                        if(isStdAssign){
                            this.selectedTech = null;
                        }else{
                            this.newUserModal.assignedTech = null;
                        }
                    })
                    .catch((err)=>{
                        if(isStdAssign){
                            this.selectedTech = null;
                        }else{
                            this.newUserModal.assignedTech = null;
                        }
                        // An error occurred
                        console.log(err)
                    })
                }else if(techRecord.user_id != null){
                    // This technician is assigned to another user, check user intent
                    var userRecord = _.find(this.rawUserRows, (usr)=>{ return usr.id == techRecord.user_id; })
                    var assignedTo = "Unknown";
                    if(userRecord != undefined){
                        assignedTo = _.escape(userRecord.display_name);
                    }
                    const contentVNode = this.$createElement('div', { domProps: { innerHTML: `Only one user can be assigned to each technician at a time.<br><br>
                    To assign this technician to the new user, select "Assign Tech To New User".<br> To leave this technician assigned to ${assignedTo}, select "Leave Tech Assignment".` } })
                    this.$bvModal.msgBoxConfirm([contentVNode], {
                        title: `Technician Already Assigned To ${userRecord.display_name}`,
                        buttonSize: "sm",
                        cancelVariant: "secondary",
                        cancelTitle: `Leave Tech Assignment`,
                        okVariant: "vai-warning-darker",
                        okTitle: "Assign Tech To New User",
                        size: "lg"
                    })
                    .then((value)=>{
                        if(!value){
                            if(isStdAssign){
                                this.selectedTech = null;
                            }else{
                                this.newUserModal.assignedTech = null;
                            }
                        }
                    })
                    .catch((err)=>{
                        if(isStdAssign){
                            this.selectedTech = null;
                        }else{
                            this.newUserModal.assignedTech = null;
                        }
                        // An error occurred
                        console.log(err)
                    })
                }
            }
        },
        openAssignTech(id){
            var technician = _.find(this.rawTechs, (tech)=>{ return tech.user_id == id; })
            var matched = _.find(this.rawUserRows, (row)=>{ return row.id == id; })
            if(matched != undefined){
                this.selectedTech = null;
                if(technician != undefined){
                    this.selectedTech = technician.id;
                }
                this.assign = matched;
                this.$bvModal.show("assign-technician")
            }
        },
        openEdituser(id){
            var matched = _.find(this.rawUserRows, (row)=>{ return row.id == id; })
            if(matched != undefined){
                this.editing = matched;
                this.$bvModal.show("edit-user")
            }
            
        },
        confirmFreezeUser(id){
            butils.instance.put(`${process.env.VUE_APP_API_BASE_URL}/account/users/${id}/freeze`)
            .then((response)=>{
                this.fetchUsers();
                butils.createToast(this, 'Success', 'User Frozen', 'success', 3);
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permissions To Update User Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action.', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Updating User', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        confirmUnFreezeUser(id){
            butils.instance.put(`${process.env.VUE_APP_API_BASE_URL}/account/users/${id}/unfreeze`)
            .then((response)=>{
                this.fetchUsers();
                butils.createToast(this, 'Success', 'User Unfrozen', 'success', 3);
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permissions To Update User Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action.', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Updating User', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        openChangePass(id){

        },
        performSetTech(){
            this.waiting = true;
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/technicians/assign_user/${this.selectedTech}/${this.assign.id}`)
            .then((response)=>{
                this.fetchUsers();
                this.assign = null;
                this.waiting = false;
                butils.createToast(this, 'Success', 'User Technician Set', 'success', 3);
                this.$bvModal.hide("assign-technician");
            })
            .catch((err)=>{
                this.waiting = false;
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Permissions To Update Technician Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action.', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Updating Technician User', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        performUserEdit(){
            var validates = true;
            this.waiting = true;
            var firstName = this.$store.getters.getFormValue({parentFormID: this.entryID, dataName: "first_name"});
            var lastName = this.$store.getters.getFormValue({parentFormID: this.entryID, dataName: "last_name"});
            var displayName = this.$store.getters.getFormValue({parentFormID: this.entryID, dataName: "display_name"});
            if(firstName.length < 1){
                butils.createToast(true, "First Name Is Required", "", "danger", 5);
                validates = false;
            }
            if(lastName.length < 1){
                butils.createToast(true, "Last Name Is Required", "", "danger", 5);
                validates = false;
            }
            if(displayName.length < 1){
                butils.createToast(true, "Display Name Is Required", "", "danger", 5);
                validates = false;
            }
            if(validates){
                var packed = {
                    first_name: firstName,
                    last_name: lastName,
                    display_name: displayName
                };
                butils.instance.put(`${process.env.VUE_APP_API_BASE_URL}/account/users/${this.editing.id}`, packed)
                .then((response)=>{
                    this.fetchUsers();
                    butils.createToast(this, 'Success', 'User Updated', 'success', 3);
                    this.editing = null;
                    this.waiting = false;
                    this.$bvModal.hide("edit-user");
                })
                .catch((err)=>{
                    this.waiting = false;
                    if(butils.isError401(err)){
                        butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    }else if(butils.isError403(err)){
                        butils.createToast(this, 'Permissions To Update User Denied By Endpoint Control', 'Contact your administrator to receive permission to perform this action.', 'warning');
                    }else{
                        console.log(err);
                        butils.createToast(this, 'An Error Occured While Updating User', 'Try again, if the problem persists, contact support', 'danger');
                    }
                })
            }else{
                this.waiting = false;
            }
        },
        fetchUsers(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/account/users`)
            .then((response)=>{
                var raw = response.data.result;
                raw.forEach(user => {
                    user.lastLoginAtParsed = null;
                    if(user.last_login_at != null){
                        user.lastLoginAtParsed = butils.formatters.timestampToDateTime(user.last_login_at, true, true)
                    }
                    if(user.disabled != null){
                        user.disabledParsed = butils.formatters.timestampToDateTime(user.disabled, true, true)
                    }else{
                        user.disabledParsed = null;
                    }
                });
                this.rawUserRows = raw;
                this.fetchTechnicians();
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'List Account users Disabled By Endpoint Control', 'Contact your administrator to receive permission to perform this action.', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Fetching Account Users', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        },
        fetchTechnicians(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/technicians/list`)
            .then((response)=>{
                var rawRows = response.data.result.records;
                this.rawTechs = rawRows;
                var choiceListValues = [{text: `No Assigned Technician`, value: null}];
                rawRows.forEach((row)=>{
                    var tmp = {text: `${row.tech_first_name} ${row.tech_last_name}`, value: row.id}
                    if(row.user_id != null){
                        var match = _.find(this.rawUserRows, (usr)=>{ return usr.id == row.user_id; })
                        if(match != undefined){
                            tmp.text += ` - Assigned To: (${match.display_name})`;
                        }else{
                            tmp.text += ` - Assigned To: (UNKNOWN)`;
                        }
                    }else{
                        tmp.text += ` - Not Assigned`;
                    }
                    choiceListValues.push(tmp);
                })
                this.technicianChoices = choiceListValues;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'List Technicians Disabled By Endpoint Control', 'Contact your administrator to receive permission to perform this action.', 'warning');
                }else{
                    console.log(err);
                    butils.createToast(this, 'An Error Occured While Fetching Account Technicians', 'Try again, if the problem persists, contact support', 'danger');
                }
            })
        }
    },
    watch:{
        newUserModal: {
            handler(newVal, oldVal){
                // Check Each Key
                if(newVal.first != null){
                    if(!!newVal.first.trim()){
                        this.newUserValStates.first = true;
                        this.newUserValStates.firstDesc = null;
                    }else{
                        this.newUserValStates.first = false;
                        this.newUserValStates.firstDesc = "First name is required."
                    }
                }else{
                    this.newUserValStates.first = false;
                    this.newUserValStates.firstDesc = "First name is required."
                }
                if(newVal.last != null){
                    if(!!newVal.last.trim()){
                        this.newUserValStates.last = true;
                        this.newUserValStates.lastDesc = null;
                    }else{
                        this.newUserValStates.last = false;
                        this.newUserValStates.lastDesc = "Last name is required."
                    }
                }else{
                    this.newUserValStates.last = false;
                    this.newUserValStates.lastDesc = "Last name is required."
                }
                if(newVal.display != null){
                    if(!!newVal.display.trim()){
                        this.newUserValStates.display = true;
                        this.newUserValStates.displayDesc = null;
                    }else{
                        this.newUserValStates.display = false;
                        this.newUserValStates.displayDesc = "Display name is required."
                    }
                }else{
                    this.newUserValStates.display = false;
                    this.newUserValStates.displayDesc = "Display name is required."
                }
                if(newVal.email != null){
                    if(!!newVal.email.trim()){
                        if(butils.validate.isEmail(newVal.email)){
                            this.newUserValStates.email = true;
                            this.newUserValStates.emailDesc = null;
                        }else{
                            this.newUserValStates.email = false;
                            this.newUserValStates.emailDesc = "Invalid Email.";
                        }
                    }else{
                        this.newUserValStates.email = false;
                        this.newUserValStates.emailDesc = "Email is required."
                    }
                }else{
                    this.newUserValStates.email = false;
                    this.newUserValStates.emailDesc = "Email is required."
                }
                if(!newVal.autoSetPass){
                    // Setting Pass Manually Has Pitfalls, Catch Them
                    if(newVal.password != null && newVal.passwordVerify != null){
                        if(!!newVal.password.trim() && !!newVal.passwordVerify.trim()){
                            // We can check if they match now
                            if(newVal.password.trim().length < 5){
                                this.newUserValStates.password = false;
                                this.newUserValStates.passwordDesc = "Password must be at least 5 characters long";
                                this.newUserValStates.passwordVerify = null;
                                this.newUserValStates.passwordVerifyDesc = null;
                            }else if(newVal.password.trim() === newVal.passwordVerify.trim()){
                                this.newUserValStates.password = true;
                                this.newUserValStates.passwordDesc = null;
                                this.newUserValStates.passwordVerify = true;
                                this.newUserValStates.passwordVerifyDesc = null;
                            }else{
                                this.newUserValStates.password = null;
                                this.newUserValStates.passwordDesc = null;
                                this.newUserValStates.passwordVerify = false;
                                this.newUserValStates.passwordVerifyDesc = "Passwords do not match.";
                            }
                        }else{
                            // Nothing to notify
                            this.newUserValStates.password = null;
                            this.newUserValStates.passwordDesc = null;
                            this.newUserValStates.passwordVerify = null;
                            this.newUserValStates.passwordVerifyDesc = null;
                        }
                    }else{
                        // Nothing to notify
                        this.newUserValStates.password = null;
                        this.newUserValStates.passwordDesc = null;
                        this.newUserValStates.passwordVerify = null;
                        this.newUserValStates.passwordVerifyDesc = null;
                    }
                }
                if(newVal.epcJSON != null){
                    this.newUserValStates.epcJSON = true;
                    this.newUserValStates.epcDesc = null;
                }else{
                    this.newUserValStates.epcJSON = null;
                    this.newUserValStates.epcDesc = null;
                }
                if(newVal.uicJSON != null){
                    this.newUserValStates.uicJSON = true;
                    this.newUserValStates.uicDesc = null;
                }else{
                    this.newUserValStates.uicJSON = null;
                    this.newUserValStates.uicDesc = null;
                }
            },
            deep: true
        }
    },
    computed:{
        showRows: function(){
            return _.filter(this.rawUserRows, (usr)=>{
                if(this.showUnBilledUsers){
                    return true;
                }else{
                    return usr.billed == true;
                }
            })
        },
        createUserCompleted: function(){
            try{
                if(!!this.newUserModal.first.trim() && !!this.newUserModal.last.trim() && !!this.newUserModal.display.trim()){
                    // None of these are null, undefined, or empty. They all have at least a single letter value
                    if(!!this.newUserModal.email.trim()){
                        // An Entry in email exists
                        if(butils.validate.isEmail(this.newUserModal.email)){
                            // Email Good
                            if(!!this.newUserModal.epcJSON.trim() && !!this.newUserModal.uicJSON.trim()){
                                // epc and uic are at least possibly okay
                                if(!this.newUserModal.autoSetPass){
                                    // Password Must Be Set
                                    if(!!this.newUserModal.password.trim() && !!this.newUserModal.passwordVerify.trim()){
                                        // Password and PassVerify are not empty
                                        if(this.newUserModal.password.trim() === this.newUserModal.passwordVerify.trim()){
                                            // Same
                                            return true;
                                        }else{
                                            // Not The Same
                                            return false;
                                        }
                                    }else{
                                        // Password or PassVerify bad
                                        return false;
                                    }
                                }else{
                                    // Auto Password is Set, Password Is Not Needed
                                    return true;
                                }
                            }else{
                                // epc or uic is junk
                                return false;
                            }
                        }else{
                            return false;
                        }
                    }else{
                        // No Email Entry
                        return false;
                    }
                }else{
                    return false;
                }
            }catch(error){
                // Doesnt matter what the error is, its not acceptable
                return false;
            }
        }
    },
    beforeCreate(){

    },
    created(){
        this.entryID = uuidv4();
        this.$store.commit('initForm',{ localFormID: this.entryID, meta: this.formDef.meta });
    },
    beforeMount (){

    },
    mounted(){
        this.resetNewUserModal();
        this.fetchUsers();
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){
        this.$store.commit('clearForm', {localFormID: this.entryID});
    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>